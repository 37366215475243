<template>
    <DataTable :value="data" :rows="limit"
        :rowsPerPageOptions="[10, 25, 50]"
        :paginator="true" :scrollable="true"
        responsiveLayout="scroll"
        :rowHover="true" @row-click="rowClick"
        :loading="loading"
        @page="currentPage($event)"
    >
        <template #empty>
            <div class="text-center font-italic">
                No data found.
            </div>
        </template>
        <Column v-for="(column, key) in columns" :key="`index_${key}`"
            :field="column.field"
            :header="column.header"
            :class="`text-${column.position ? column.position : 'center'}`"
            :sortable="column.sortable">
            <template v-if="column.process" #body="slotProps">
                <template v-if="column.field === 'number_order'">
                    {{ column.process(slotProps.index) }}
                </template>
                <template v-else-if="column.process(slotProps.data[column.field]).type === 'tag'">
                    <Tag
                        :class="column.process(slotProps.data[column.field]).class"
                        :severity="column.process(slotProps.data[column.field]).class"
                        :value="column.process(slotProps.data[column.field]).label"
                        :icon="column.process(slotProps.data[column.field]).icon ?? null">
                    </Tag>
                </template>
                <template v-else-if="column.type === 'toggle'">
                    <div class="flex align-items-center">
                        <InputSwitch v-model="slotProps.data.is_blocked"
                            @change.stop="onToggle(slotProps.index, slotProps.data.is_blocked, slotProps.data)"
                        />
                        <label class="text-black opacity-60 ml-2">
                            Blocked
                        </label>
                    </div>
                </template>
                <template v-else>
                    {{ column.process(slotProps.data[column.field]) }}
                </template>
            </template>
            <template v-else-if="column.field === 'icon' || column.field === 'icon_url' || column.field === 'imageUrl' || column.field.includes('logo')" #body="slotProps">
                <img :src="slotProps.data[column.field]" alt="" height="25">
            </template>
        </Column>
        <Column header="Aksi" class="text-center" v-if="actions">
            <template #body={data}>
                <div class="flex justify-content-center">
                    <Button icon="pi pi-refresh" class="p-button-rounded p-button-info mr-2" title="Reset" @click="onReset(data)" v-if="actions.includes('reset')" />
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" @click="onEdit(data)" v-if="actions.includes('edit')" />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="onDelete(data)" v-if="actions.includes('delete')" />
                    <Button icon="pi pi-unlock" class="p-button-rounded p-button-info mr-2" title="Unblock Password" @click="onUnblock(data)" v-if="actions.includes('unblock') && data.passwd_attempt >= 3" />
                    <Button icon="pi pi-lock-open" class="p-button-rounded p-button-info" title="Unblock Pin" @click="onUnblockPin(data)" v-if="actions.includes('unblockPin') && data.pin_attempt >= 3" />
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    name: 'table-list',
    props: [
        'columns', 'data', 'offset', 'limit', 'loading', 'show_confirm_deletion',
        'is_cannot_edit', 'is_cannot_delete', 'actions'
    ],
    emits: ['rowClick', 'callbackPaginate', 'edit', 'delete', 'reset', 'unblock', 'unblockPin'],
    components: {
        DataTable, Column,
    },
    data() {
        return {
            storage: null,
            delete_confirmation: false,
        }
    },
    methods: {
        rowClick(event) {
            this.$emit('rowClick', event)
        },
        currentPage(event) {
            this.$emit('callbackPaginate', event)
        },
        onEdit(data) {
            this.$emit('edit', data)
        },
        onDelete(data) {
            this.$emit('delete', data)
        },
        onReset(data) {
            this.$emit('reset', data)
        },
        onUnblock(data) {
            this.$emit('unblock', data)
        },
        onUnblockPin(data) {
            this.$emit('unblockPin', data)
        },
        onToggle(index, value, data) {
            this.$emit('toggle', {
                index: index,
                value: value,
                data: data,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep(.p-button.p-button-icon-only.p-button-rounded) {
    width: 23px;
    height: 23px;
}
::v-deep(.p-button.p-button-icon-only.p-button-rounded .pi) {
    font-size: 12px;
}
::v-deep(.p-column-title) {
    white-space: nowrap;
}
</style>