<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Service Name *
                </label>
                <Dropdown v-model="form.selected_service" :options="services" optionLabel="name" placeholder="Pilih Service" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Response Code *
                </label>
                <InputText type="text" placeholder="Response Code" v-model="form.response_code" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Response Message
                </label>
                <InputText type="text" placeholder="Response Message" v-model="form.response_message" :disabled="form.is_submit" />
            </div>
        </form>
    </div>
</template>

<script>

export default {
    props: ['data','services'],
    data() {
        return {
            form: {
                id: null,
                selected_service: '',
                service_name: '',
                response_code: '',
                response_message: '',
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.form.service_name = this.form.selected_service ? this.form.selected_service['name'] : ''
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.selected_service = this.services.find(obj => obj.name == this.form.service_name);
            if (!this.form.selected_service) {
                this.form.selected_service = this.services[0]
            }
        }
    },
}
</script>

<style lang="scss" scoped></style>
